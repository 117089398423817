<p-toast position="top-right" key="msj"></p-toast>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" acceptLabel="Aceptar" rejectLabel="Cancelar"
  rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-confirmDialog [style]="{width: '50vw'}" key="sinInformacionDialog" [baseZIndex]="10000" acceptLabel="Aceptar"
  rejectLabel="Cancelar" rejectButtonStyleClass="p-button-text" styleClass="color-confirmar" [rejectVisible]="false">

</p-confirmDialog>
<div class="container-fluid" style="border-color: #1e5a9c; border: 5px ; width: 95%;">
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card card-shadow sinborde">
        <div class="card-header sinborde" style="background: none; ">
          <div class="cardicono rounded">
            <i class="pi pi-credit-card mt-3" style="font-size: 2rem; color: #fff;"></i>
          </div>
          <header style="left: 11%; top: 5%;">Vale
            <div style="color:#1e5a9c; display: inline;"> de Despensa</div>
          </header>
          <div class="row d-flex justify-content-end">
            <div class="col-md-12">


              <!-- -------------fecha del filtro---------------- -->
              <div class="form-group row mt-5">
                <div class="col-md-6 row">
                  <div class="row col-md-1 mt-2"> </div>
                  <div class="row col-md-8 mt-2">
                    <div class="col-md-5">
                      <div class="row ml-1">
                        <label style="color: #155a8a; font-weight: bold;">Mes correspondiente</label>
                      </div>
                      <div class="row">
                        <p-dropdown [options]="meses" optionLabel="nombre" [(ngModel)]="num_mes_filtro"
                          autoWidth="false" [showClear]="false" placeholder="Seleccione un mes"
                          [style]="{'width':'100%', 'margin-top': '5px', 'min-width': '120px'}" optionValue="num_mes">
                        </p-dropdown>
                      </div>

                    </div>
                    <div class="col-md-7 mt-1">

                      <div class="row ml-1">
                        <label style="color: #155a8a; font-weight: bold;">Semana de Pago</label>
                      </div>
                      <div class="row">
                        <p-dropdown [options]="lstSemanas" autoWidth="false" [style]="{'width':'100%'}"
                          placeholder="Seleccione Semana*" [(ngModel)]="selectedSemana" optionLabel="nom_semana"
                          [showClear]="false">
                          <ng-template let-target pTemplate="item">
                            <div class="ui-helper-clearfix" style="position: relative; height: 25px;"
                              pTooltip="{{getTargetDescription(target)}}" tooltipPosition="top">
                              <span style="font-size: 14px; float: left; margin-top: 4px">{{target.nom_semana}}</span>
                            </div>
                          </ng-template>
                        </p-dropdown>
                        <div *ngIf="selectedSemana != null">
                          <div class="row">
                            <div style="color: #4fbe4f;" class="col-sm-6 text-left"><b>Inicio:
                                {{selectedSemana.fec_inicio | date: 'dd/MM/yyyy'}}</b></div>
                            <div style="color: #4fbe4f;" class="col-sm-6 text-right"><b>Fin:
                                {{selectedSemana.fec_fin | date: 'dd/MM/yyyy'}}</b></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- filtro de ciudades -->
                  <div class="col-md-2 mt-4">
                    <p-dropdown [(ngModel)]="id_ciudad_principal" [options]="lstCiudad" optionLabel="nombre"
                      optionValue="id" placeholder="Filtrar por Ciudad" [showClear]="false">
                    </p-dropdown>

                  </div>
                </div>
                <div class="col-md-6 row">

                  <div class="col-md-2 ml-3 mt-4">
                    <button pButton pRipple type="button" (click)="FiltrarInformacion();" label="Filtrar"
                      icon="pi pi-filter" iconPos="left" class="custom-button"></button>
                  </div>


                  <div class=" col-md-6 d-flex justify-content-end mt-4">
                    <div>
                      <div class="col-md-6">
                        <div class="row d-flex justify-content-around">
                          <div class="col-md-9">
                            <p-calendar [(ngModel)]="anio_filtro" view="year" [showIcon]="true" dateFormat="yy"
                              inputId="yearpicker" [style]="{'width':'100%','height':'80%'}"
                              pTooltip="Año de Semanas a Consultar" tooltipPosition="top"></p-calendar>
                          </div>
                          <div class="col-md-3">
                            <button pButton pRipple label="Cargar" class="custom-buttoncargar" icon="pi pi-filter"
                              (click)="cargarListadoDeSemanasAnio_cast(anio_filtro);"
                              pTooltip="Cargar Semanas del Año"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 mt-4">
                    <button pButton pRipple type="button" (click)="abrirModalReportes();" label="Exportar"
                      icon="pi pi-file-excel" iconPos="left" class="custom-button-excel"></button>
                  </div>
                </div>




              </div>

            </div>





          </div>

        </div>
        <div class="card-body">
          <p-table #dt1 [value]="lstValeDespensa" [scrollable]="true" scrollHeight="flex" scrollDirection="both"
            [paginator]="true" [rows]="150" [rowsPerPageOptions]="[50,75,100,200]" [showCurrentPageReport]="true"
            currentPageReportTemplate=" {first} a {last} de {totalRecords} Registros"
            [globalFilterFields]="['nom_chofer','desc_ciudad_hm','desc_puesto']" [loading]="loading">
            <ng-template pTemplate="caption">
              <div class="flex justify-content-between">
                <!-- Contenedor para el botón y dropdown -->
                <div class="flex" style="width: 100%; align-items: center;">
                  <!-- Botón "Nuevo Apoyo" -->
                  <button pButton pRipple type="button" (click)="GuardarVale();" label="Guardar todo" icon="pi pi-save"
                    [style]="{'width':'auto'}" iconPos="left" class="custom-button" style="margin-right: 10px;">
                  </button>

                  <!-- Dropdown de Mes -->
                  <p-dropdown [options]="meses" optionLabel="nombre" [(ngModel)]="num_mes" autoWidth="false"
                    [showClear]="false" placeholder="Seleccione un mes"
                    [style]="{'width':'auto', 'margin-top': '5px', 'min-width': '120px'}" optionValue="num_mes">
                  </p-dropdown>
                </div>

                <!-- Campo de búsqueda -->
                <div class="p-input-icon-left p-ml-auto" *ngIf="habilitaragregar">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                    placeholder="Buscar" />
                </div>

                <div class="p-input-icon-left p-ml-auto" *ngIf="!habilitaragregar">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" size="40" (input)="applyFilterGlobal($event, 'contains')"
                    placeholder="Buscar" />
                </div>
              </div>
            </ng-template>

            <ng-template pTemplate="header">
              <tr>

                <th class="text-center">#Chofer</th>
                <th class="text-start" pSortableColumn="nom_chofer">Colaborador<p-sortIcon
                    field="nom_chofer"></p-sortIcon></th>
                <th class="text-center" pSortableColumn="desc_puesto">Puesto<p-sortIcon
                    field="desc_puesto"></p-sortIcon></th>
                <th class="text-center" pSortableColumn="id_ciudad_hm">Ciudad<p-sortIcon
                    field="id_ciudad_hm"></p-sortIcon></th>
                <th class="text-center">Semana</th>
                <th class="text-center">Mes</th>
                <th class="text-center" pSortableColumn="num_rutas_promedio_local">Fecha de ingreso<p-sortIcon
                    field="num_rutas_promedio_local"></p-sortIcon> </th>
                <th class="text-center" pSortableColumn="imp_salario_colaborador">Importe<p-sortIcon
                    field="imp_salario_colaborador"></p-sortIcon> </th>
                <th class="text-center" pSortableColumn="clv_activo">¿Guardado?<p-sortIcon
                    field="clv_activo"></p-sortIcon> </th>
                <th class="text-center">¿Beneficio?</th>
                <th class="text-center">Eliminar</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
              <tr>
                <td class="text-center"><b>{{data.cod_chofer}}</b>
                <td class="text-left">
                  <b>{{data.nom_chofer}}
                    <p *ngIf="data.clv_baja === 0" style="color: #f75c4d; display: inline;">(BAJA)</p>
                  </b>
                </td>

                <td class="text-center" [ngStyle]="{'color': data.desc_puesto === 'DRIVER' ? '#1e5a9c' : '#70c0ec'}">
                  <!-- Azul Marino o Naranja -->
                  <b>{{data.desc_puesto}}</b>
                </td>
                <td class="text-center">{{data.desc_ciudad_hm}}</td>
                <td class="text-center">{{data.id_semana}}</td>
                <td class="text-center">{{this.getMesNombre(this.num_mes_filtro)}}</td>




                <td class="text-center" style="color: #FF6F61;"><b>{{data.fec_alta | date: 'dd/MM/yyyy' }}</b></td>

                <td class="text-center" style=" color: #000000;">
                  <b>{{data.imp_vale_despensa | currency: 'USD'}}</b>
                </td>
                <td class="text-center" *ngIf="data.clv_guardado === true">
                  <p class="font-weight-bold text-center" style="color: rgb(79, 224, 60);"><b>Guardado</b></p>
                </td>

                <!-- Fila para "Sin Guardar" (cuando clv_activo es false) -->
                <td class="text-center" *ngIf="data.clv_guardado === false">
                  <p class="font-weight-bold text-center" style="color: rgb(224, 82, 60);"><b>Sin Guardar</b></p>
                </td>

                <td class="text-center">
                  <p-checkbox *ngIf="data.clv_guardado === false" [(ngModel)]="data.clv_activo" [binary]="true"
                    variant="filled"></p-checkbox>
                </td>

                <td class="text-center">
                  <button *ngIf="data.clv_guardado === true" class="btn btn-danger text-center"
                    pTooltip="Eliminar Registro" (click)="confirmarEliminar(data);">
                    <i class="pi pi-delete-left"></i>
                  </button>
                </td>


              </tr>
            </ng-template>
          </p-table>

        </div>
      </div>
    </div>




    <!--
  pdialog de reporte -->

  <p-dialog [resizable]="false" [draggable]="false" [(visible)]="dbsReportes"
  [style]="{'width': '60vw','height':'620px'}" [transitionOptions]="'0ms'" styleClass="card-shadow"
  (onHide)="cancelarModalReportes()">
  <ng-template pTemplate="header">
    Generar Reporte De Excel
  </ng-template>
  <ng-template pTemplate="content">
    <div class="mt-5">
      <hr class="style-two">
      <div class="row">
        <div style="color: #5de25d" class="col-md-3 mt-5">
          <p class="texto-pesado"><b>Reporte Bono de Desempeño</b></p>
        </div>
        <div class="col-md-2">
          <div class="row">
            <small style="color: #155a8a; font-weight: bold;">Año</small>
            <select class="form-control" style="width: 70%;" [(ngModel)]="num_anio_rpt">
              <option *ngFor="let year of years" [value]="year">{{ year }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="row">
            <small style="color: #155a8a; font-weight: bold;">Mes correspondiente</small>
            <p-dropdown [options]="meses" optionLabel="nombre" [(ngModel)]="num_mes_rpt" autoWidth="false"
              [showClear]="false" placeholder="Seleccione un mes"
              [style]="{'width':'100%', 'margin-top': '5px', 'min-width': '120px'}" optionValue="num_mes">
            </p-dropdown>
          </div>


        </div>

        <div class="col-md-3 justify-content-end">
          <div class="row">
            <small style="color: #155a8a; font-weight: bold;">Ciudad</small>
            <p-dropdown [(ngModel)]="id_ciudad_principalrpt" [options]="lstCiudad" optionLabel="nombre"
              optionValue="id" placeholder="Filtrar por Ciudad" [showClear]="false" [disabled]="dsblstCiudades">
            </p-dropdown>
          </div>
        </div>

        <div class="col-md-2  mt-3">
          <button type="button" [disabled]="deshabilitarbtnexportar" class="custom-button-excel" style="width:90%;"
            (click)="exportarExcel()" [disabled]="ButtonExportExcel">
            Exportar <i class="pi pi-file-export"></i>
          </button>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-2 text-center">
          <i class="pi pi-spin pi-spinner" *ngIf="loading_reporte1"></i>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
      (click)="cancelarModalReportes();"></button>
  </ng-template>
</p-dialog>



  </div>
