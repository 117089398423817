import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { MainComponent } from './shared/main/main.component';
import { MantenimientoComponent } from './shared/mantenimiento/mantenimiento.component';
import { ManualComponent } from './shared/manual/manual.component';
import { PrestamoComponent } from './shared/prestamo/prestamo.component';
import { IncapacidadComponent } from './shared/incapacidad/incapacidad.component';
import { BonopuntualidadComponent } from './shared/bonopuntualidad/bonopuntualidad.component';
import { SemanasComponent } from './shared/semanas/semanas.component';
import { DiasfestivosComponent } from './shared/diasfestivos/diasfestivos.component';
import { SalariosComponent } from './shared/salarios/salarios.component';
import { SalariodiarioComponent } from './shared/salariodiario/salariodiario.component';
import { DescuentosvariosComponent } from './shared/descuentosvarios/descuentosvarios.component';
import { PrestamoviaticosComponent } from './shared/prestamoviaticos/prestamoviaticos.component';
import { ConsultanominaComponent } from './shared/consultanomina/consultanomina.component';
import { HistoriconominaComponent } from './shared/historiconomina/historiconomina.component';
import { InasistenciasComponent } from './shared/inasistencias/inasistencias.component';
import { BonopermanenciaComponent } from './shared/bonopermanencia/bonopermanencia.component';
import { CajaahorroComponent } from './shared/cajaahorro/cajaahorro.component';
import { ApoyoestatusforaneoComponent } from './shared/apoyoestatusforaneo/apoyoestatusforaneo.component';
import { BonoaguinaldoComponent } from './shared/bonoaguinaldo/bonoaguinaldo.component';
import { VacacionesComponent } from './shared/vacaciones/vacaciones.component';
import { PrimavacacionalComponent } from './shared/primavacacional/primavacacional.component';
import { BonorecomendacionComponent } from './shared/bonorecomendacion/bonorecomendacion.component';
import { InfonavitComponent } from './shared/infonavit/infonavit.component';
import { ConsultadeduccionesComponent } from './shared/consultadeducciones/consultadeducciones.component';
import { RecibodigitalComponent } from './shared/recibodigital/recibodigital.component';
import { FiniquitoComponent } from './shared/finiquito/finiquito.component';
import { CajaahorroanualComponent } from './shared/cajaahorroanual/cajaahorroanual.component';
import { ApoyosComponent } from './shared/apoyos/apoyos.component';
import { DiafestivoComponent } from './shared/diafestivo/diafestivo.component';
import { SalariopromediosemanalComponent } from './shared/salariopromediosemanal/salariopromediosemanal.component';
import { ValedespensaComponent } from './shared/valedespensa/valedespensa.component';
import { BonocontratacionComponent } from './shared/bonocontratacion/bonocontratacion.component';
import { BonodesempenoComponent } from './shared/bonodesempeno/bonodesempeno.component';


const routes: Routes = [
  {
    path: "principal",
    component: MainComponent,
    children: [
      {
        path: "mantenimiento",
        component: MantenimientoComponent
      },
      {
        path: "manual",
        component: ManualComponent
      },
      {
        path:"prestamo",
        component: PrestamoComponent
      },
      {
        path: "incapacidad",
        component: IncapacidadComponent
      },
      {
        path:"puntualidad",
        component: BonopuntualidadComponent
      },
      {
        path:"sem",
        component: SemanasComponent
      },
      {
        path:"dias",
        component: DiasfestivosComponent
      },
      {
        path:"tab",
        component: SalariosComponent
      },
      {
        path:"salario",
        component: SalariodiarioComponent
      },
      {
        path:"descuentos",
        component: DescuentosvariosComponent
      },
      {
        path:"viatico",
        component: PrestamoviaticosComponent
      },

      {
        path:"nomina",
        component: ConsultanominaComponent
      },

      {
        path:"hisnomina",
        component: HistoriconominaComponent
      },
      {
        path:"inas",
        component: InasistenciasComponent
      },
      {
        path:"permanencia",
        component: BonopermanenciaComponent
      },
      {
        path:"ahorro",
        component: CajaahorroComponent
      },
      {
        path:"apfor",
        component: ApoyoestatusforaneoComponent
      },
      {
        path:"bonag",
        component: BonoaguinaldoComponent
      },
      {
        path:"vac",
        component: VacacionesComponent

      },
      {
        path:"prima",
        component: PrimavacacionalComponent
      },
       {
        path:"brecom",
        component: BonorecomendacionComponent
      },
      {
        path:"infonavit",
        component: InfonavitComponent
      },
      {
        path:"deduc",
        component: ConsultadeduccionesComponent
      },
      {
        path:"recibdig",
        component: RecibodigitalComponent
      },
      {
        path:"finiq",
        component: FiniquitoComponent
      },
      {
        path:"caa",
        component: CajaahorroanualComponent
      },
      {
        path:"apoyo",
        component: ApoyosComponent
      },
      {
        path:"diafest",
        component: DiafestivoComponent
      },
      {
        path:"promsem",
        component: SalariopromediosemanalComponent
      }
      ,
      {
        path:"valdesp",
        component: ValedespensaComponent
      },
      {
        path:"boncontr",
        component: BonocontratacionComponent
      },
      {
        path:"bdesem",
        component: BonodesempenoComponent
      }

    ]
  }
  ,
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    useHash: true}
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
