import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CiudadesprincipalesService } from './ciudadesprincipales.service';

import { IBonoDesempeno } from '../models/bonodesempeno';

@Injectable({
  providedIn: 'root'
})
export class BonoDespensaservice{

  private apiUrl: string = environment.sUrlNomina + 'BonoDesempeno';

  constructor(private http: HttpClient, private ciudadesPrincipalesService: CiudadesprincipalesService) { }  // Inyección de CiudadesprincipalesService


 getbonodesepeno(num_anio: number,id_semana:number,num_mes_filtro:number ,id_ciudad_hm: number): Observable<IBonoDesempeno[]> {
  const valores = {
    params: new HttpParams().
       set('num_anio',num_anio).
       set('id_semana',id_semana).
       set('num_mes_filtro',num_mes_filtro).
       set('id_ciudad_hm',id_ciudad_hm)

  }
  return this.http
      .get<IBonoDesempeno[]>(this.apiUrl +'/getbonodesem',valores)
      .pipe(retry(1), catchError(this.handleError));

 }


public postbonodesepeno(data: any):Observable<any>{
  const headers = { 'content-type': 'application/json'}
  const body = JSON.stringify(data);
  return this.http.post<any>(this.apiUrl + '/Agrbonodesem/',body,{'headers':headers});
 }


 public deletebonodesepeno(data: any):Observable<any>{
  const headers = { 'content-type': 'application/json'}
  const body = JSON.stringify(data);
  return this.http.post<any>(this.apiUrl + '/deletebonodesem/',body,{'headers':headers});
 }


 getrptbonodesepeno(num_anio: number,num_mes_rpt:number ,id_ciudad_hm: number): Observable<any[]> {
  const valores = {
    params: new HttpParams().
       set('num_anio',num_anio).
       set('num_mes_rpt',num_mes_rpt).
       set('id_ciudad_hm',id_ciudad_hm)

  }
  return this.http
      .get<any[]>(this.apiUrl +'/rptbonodesem',valores)
      .pipe(retry(1), catchError(this.handleError));

 }

  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

}
